import React from 'react';
import '../../styles/global/global.css';
import Footer from '../footer';
import NavMenu from '../nav-menu';

interface Props {
  children: React.ReactNode;
}

const GlobalLayout = ({ children }: Props): JSX.Element => {
  return (
    <>
      <NavMenu />
      {children}
      <Footer />
    </>
  );
};

export default GlobalLayout;
