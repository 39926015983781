import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFacebook, faGithub, faInstagram, faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { ContentfulSocialMediaAccount } from "../types/graphql-types";

/**
 * data from graphql query
 */
interface Props {
  allContentfulSocialMediaAccount: {
    nodes: ContentfulSocialMediaAccount[];
  };
}

const icons: { [key: string]: IconProp } = {
  github: faGithub,
  linkedin: faLinkedin,
  instagram: faInstagram,
  email: faEnvelopeSquare,
  facebook: faFacebook,
  twitter: faTwitter,
};

const SocialLinks = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulSocialMediaAccount(sort: { fields: accountType }) {
            nodes {
              accountType
              accountLink
            }
          }
        }
      `}
      render={(data: Props) => {
        const socialMediaAccounts = data.allContentfulSocialMediaAccount.nodes;

        return (
          <ul>
            {socialMediaAccounts.map((account: ContentfulSocialMediaAccount) => {
              return (
                <li key={account.id}>
                  <a href={`${account.accountLink}`}>
                    <FontAwesomeIcon icon={icons[account.accountType?.toLowerCase() as string]} /> {account.accountType}
                  </a>
                </li>
              );
            })}
          </ul>
        );
      }}
    />
  );
};

export default SocialLinks;
