import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import { ContentfulPage } from '../types/graphql-types';
import * as styles from './../styles/modules/nav-bar.module.css';
import Modal from './animated/modal';
import SocialLinks from './social-links';
import ThemeToggle from './theme-toggle';

const NavMenu = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);

  const { site, allContentfulPage, allContentfulProject, allContentfulBlogPost } = useStaticQuery(query)

  return (
    <header>
      <nav className={styles.nav}>
        <h1 className={styles.navHeading}>{site.siteMetadata.defaultTitle}</h1>
        <ul className={styles.navList}>
          {allContentfulPage.nodes.map((node: ContentfulPage) => (
            <li key={node.contentful_id} className={styles.li}>
              <Link to={node.slug as string} className={styles.link} activeClassName={styles.active}>{node.title}</Link>
            </li>
          ))}
          {allContentfulProject.totalCount > 0 && (
            <li className={styles.li}>
              <Link className={styles.link} activeClassName={styles.active} to='/projects'>
                Projects
              </Link>
            </li>
          )}
          <li className={styles.li}>
              <button className={styles.link} onClick={() => setIsOpen(!isOpen)}>Connect</button>
              <Modal modalVisible={isOpen} modalSetVisible={setIsOpen}>
                <h1>Lets Connect!</h1>
                <p>What to reach out for a collaboration or maybe just say hi? Feel free to connect with me using any of the below methods!</p>
                <SocialLinks />
              </Modal>
          </li>
          <li className={styles.li}>
            <ThemeToggle />
          </li>
        </ul>
      </nav>
    </header>
  );
};

const query = graphql`
  query Navbar {
    site {
      siteMetadata {
        defaultTitle
      }
    }
    allContentfulPage {
      nodes {
        slug
        title
      }
    }
    allContentfulProject {
      totalCount
    }
  }
`

export default NavMenu;
