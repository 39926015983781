import React, { useContext, useEffect, useState } from "react";
import { BiMoon, BiSun } from "react-icons/bi";
import { ThemeContext, Themes } from "../context/theme-context";
import * as styles from "../styles/modules/theme-toggle.module.css";

const ThemeToggle = React.memo(() => {
  // Get the current theme from the context.
  const { theme, setTheme, didMount } = useContext(ThemeContext);

  // Use state is used to toggle the theme icon
  // when the theme changes.
  const [isDark, setIsDark] = useState(false);

  // When the theme changes, update the icon
  // by setting the isDark state.
  useEffect(() => {
    setIsDark(theme === Themes.Dark);
  }, [theme]);

  /**
   * Toggles the theme between light and dark.
   */
  const toggleTheme = () => {
    setTheme(isDark ? Themes.Light : Themes.Dark);
  };

  return (
    <button
      key={theme}
      onClick={toggleTheme}
      className={styles.themeToggle}
      aria-label={`Toggle theme to ${isDark ? "light" : "dark"}`}>
      {isDark ? <BiMoon /> : <BiSun />}
    </button>
  );
});

export default ThemeToggle;
