import * as React from 'react'
import { GatsbySSR } from 'gatsby';
import { ThemeProvider } from './src/context/theme-context';
import GlobalLayout from './src/components/layout/global-layout';

export const wrapPageElement: GatsbySSR['wrapPageElement'] = ({ element }) => {
  return <ThemeProvider>
    <GlobalLayout>
      {element}
    </GlobalLayout>
  </ThemeProvider>;
};
