import React, {useState, useEffect} from "react";

// In order to avoid the dreaded white flash, 
// there is JS code that runs before the page is rendered
// that is defined in gatsby-ssr.tsx.
// In there, the theme names should match with here.
export enum Themes {
  Light = "light",
  Dark = "dark",
}

type ThemeContextType = {
  theme: Themes;
  setTheme: (name: Themes) => void;
  didMount: boolean;
};

const defaultTheme: ThemeContextType = {
  theme: Themes.Light,
  setTheme: () => {},
  didMount: false,
}

export const ThemeContext = React.createContext<ThemeContextType>(defaultTheme);

type Props = {
  children: React.ReactNode;
};

export const ThemeProvider = ({ children }: Props) => {
  const [state, setState] = useState<Themes>(document.body.getAttribute("data-theme") as Themes);
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setDidMount(true);
  }, []);

  const setTheme = (name: Themes) => {
    document.body.setAttribute("data-theme", name);
    localStorage.setItem('theme', name);
    setState(name);
  };

  return <ThemeContext.Provider value={{ theme: state, setTheme: setTheme, didMount: didMount }}>{children}</ThemeContext.Provider>;
};