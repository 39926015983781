import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import * as styles from './../styles/modules/layout.module.css';

/**
 * A footer with a copyright notice and site name.
 * @returns {JSX.Element}
 */
const Footer = () => {
    const { site } = useStaticQuery(query)

    return (
        <footer className={`${styles.footer}`}>
            © {new Date().getFullYear()}, {site.siteMetadata.defaultTitle}
        </footer>
    );
}

const query = graphql`
  query Footer {
    site {
      siteMetadata {
        defaultTitle
      }
    }
  }
`

export default Footer;