import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";
import * as styles from "../../styles/modules/modal.module.css";

interface Props {
  children: React.ReactNode;
  modalVisible: boolean;
  modalSetVisible: (modalVisible: boolean) => void;
}

function ModalButton({ children, modalVisible, modalSetVisible }: Props): JSX.Element {
  useEffect(() => {
    if (modalVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [modalVisible]);

  return (
    <AnimatePresence>
      {modalVisible && <motion.div
        className={styles.modalBackground}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        onClick={() => modalSetVisible(false)}
      >
        <motion.div
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -50 }}
          className={styles.modalContent}
        >
          {children}
          <button className={styles.closeButton} onClick={() => modalSetVisible(false)}>
            Close
          </button>
        </motion.div>
      </motion.div>}
    </AnimatePresence>
  );
}

export default ModalButton;
